<template>
  <div id="marking-content">
    <el-row id="marking-content-header">
      <el-input
        placeholder
        v-model="examInfo.examName"
        style="width: 600px"
        :disabled="true"
      >
        <template slot="prepend">当前考试</template>
      </el-input>
      <el-button
        v-throttle
        type="primary"
        icon
        style="margin-left: 20px"
        @click="lookRule"
        >查看规则</el-button
      >
      <el-button
        v-throttle
        type="primary"
        icon="el-icon-refresh"
        @click="refresh"
        style="float: right"
        >刷新</el-button
      >
    </el-row>

    <el-row id="content-body-progress" :gutter="10">
      <el-col :span="8">
        <el-card shadow="always">
          <p>阅卷进度</p>
          <el-progress
            type="circle"
            :percentage="personalRecord.markRate"
            :stroke-width="progressStrokeWidth"
            :width="progressWidth"
          ></el-progress>
          <div class="paper-info-contetnt">
            <div>
              <span>学科</span>
              <span>总考卷</span>
              <span>已阅考卷</span>
              <span>剩余考卷</span>
            </div>

            <div>
              <span>
                <span class="sub-flex-span"></span>
                <span style="position: relative; left: 4px">
                  <span v-if="personalRecord.subjectType == 1">物理</span>
                  <span v-else-if="personalRecord.subjectType == 2">生物</span>
                  <span v-else-if="personalRecord.subjectType == 3">化学</span>
                  <span v-else>未知</span>
                </span>
              </span>
              <span>
                <span class="badge-flex-span"></span>
                {{ personalRecord.markTotal }} 份
              </span>
              <span>
                <span class="badge-flex-span"></span>
                {{ personalRecord.markNum }} 份
              </span>
              <span>
                <span class="badge-flex-span"></span>
                {{ personalRecord.remainingNum }} 份
              </span>
            </div>

            <div>
              <span>待仲裁考卷</span>
              <span>平均评卷</span>
              <span>平均分</span>
            </div>

            <div>
              <span>
                <span class="badge-flex-span"></span>
                {{ personalRecord.waitArbitrationNum }} 份
              </span>
              <span>
                <span class="badge-flex-span"></span>
                {{ personalRecord.markAverage }} 份
              </span>
              <span>
                <span class="badge-flex-span"></span>
                {{ personalRecord.average }} 分
              </span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card shadow="always">
          <p style="display: flex; justify-content: space-between">
            我的进度
            <a
              v-if="this.markTeacher.teacherType == 3"
              href="#markingTeacher"
              style="
                font-size: 14px;
                cursor: pointer;
                text-decoration: none;
                color: #778ca2;
              "
              >查看组员进度></a
            >
          </p>
          <el-row class="my-progress">
            <el-col :span="8">
              <img
                src="./img/defaultManActive.png"
                alt
                width="149px"
                height="149px"
              />
              <div>
                <el-button
                  v-throttle
                  type="primary"
                  round
                  icon
                  size="medium"
                  style="width: 125px; font-size: 18px"
                  >{{ markTeacher.teacherName
                  }}<span style="font-size: 14px; color: #ffaf6e">
                    ({{
                      this.markTeacher.teacherType == 3 ? '组长' : '组员'
                    }})</span
                  ></el-button
                >
              </div>
            </el-col>
            <el-col :span="16">
              <div>
                <ul>
                  <li
                    v-for="operationTest in personalCountList"
                    :key="operationTest.operationTestId"
                  >
                    <span>{{ operationTest.operationTestName }}</span>
                    <span>{{ operationTest.markNum }}道</span>
                    <span>平均{{ operationTest.operationTestAverage }}分</span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

    <el-row class="start-marking-content">
      <el-form label-width="90px">
        <el-form-item label="选择题目">
          <el-select
            v-model="chonseOperationTestId"
            placeholder="请选择题目"
            @change="operationTestChange"
          >
            <el-option
              v-for="scoreQuestion in questionList"
              :key="scoreQuestion.operationTestId"
              :value="scoreQuestion.operationTestId"
              :label="scoreQuestion.operationTestName"
            >
              <div>
                {{ scoreQuestion.operationTestName }}
                (剩余{{ scoreQuestion.num }}份)
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            icon="el-icon-tickets"
            size="medium"
            :disabled="operationTestDisabled"
            @click="startMarkingTest"
            >开始评卷</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div>
      <el-row class="personal-marking-record">
        <p>个人评卷记录</p>
        <el-form :inline="true">
          <el-form-item label="评分时间" class="formItemBoxStyle">
            <el-date-picker
              v-model="markRecordQueryParam.markTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="
                markTimeChange(markRecordQueryParam.markTime, 'markRecord')
              "
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="评分范围" class="formItemBoxStyle">
            <el-input
              v-model="markRecordQueryParam.scoreStart"
              style="width: 80px"
              class="inputScore"
              @input="
                (e) => (markRecordQueryParam.scoreStart = $verifyInput(e, 1))
              "
              @blur="reloadMarkRecordList"
            ></el-input
            >&nbsp;至&nbsp;
            <el-input
              v-model="markRecordQueryParam.scoreEnd"
              style="width: 80px"
              class="inputScore"
              @input="
                (e) => (markRecordQueryParam.scoreEnd = $verifyInput(e, 1))
              "
              @blur="reloadMarkRecordList"
            ></el-input>
          </el-form-item>

          <el-form-item label="选择题目" class="formItemBoxStyle">
            <el-select
              v-model="markRecordQueryParam.operationTestId"
              placeholder="请选择题目"
              style="width: 260px"
              @change="reloadMarkRecordList"
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in markRecordResultParam.operationTests"
                :key="item.operationTestId"
                :label="item.operationTestName"
                :value="item.operationTestId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择状态" class="formItemBoxStyle">
            <el-select
              v-model="markRecordQueryParam.markStatus"
              placeholder="请选择状态"
              style="width: 120px"
              @change="reloadMarkRecordList"
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in markRecordResultParam.markStatuses"
                :key="item.status"
                :label="item.description"
                :value="item.status"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              v-throttle
              type="primary"
              icon="el-icon-refresh"
              @click="resetMarkRecordQueryParam"
              >刷新</el-button
            >
          </el-form-item>
        </el-form>
        <el-card shadow="always">
          <!-- ,'backgroundColor':'#dfe6ec' -->
          <el-table
            v-loading="loading"
            stripe
            border
            ref="paperMarkingTable"
            :data="markRecordList"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              background: '#DFE6EC',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="gmtRating"
              label="评分时间"
              show-overflow-tooltip
              :formatter="customFormatter"
            ></el-table-column>
            <el-table-column
              prop="score"
              label="我的评分"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="operationTestName"
              label="题目名称"
              show-overflow-tooltip
              :width="500"
            ></el-table-column>
            <el-table-column
              prop="markStatus"
              label="状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.markStatus == 1">已评阅</span>
                <span v-if="scope.row.markStatus == 2">评阅中</span>
                <span v-if="scope.row.markStatus == 3">待仲裁</span>
                <span v-if="scope.row.markStatus == 4">已仲裁</span>
                <span v-if="scope.row.markStatus == 5">已抽查</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" :min-width="30" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  v-throttle
                  v-if="
                    (markRuleInfo.markRule == 1 && scope.row.markStatus == 1) ||
                    (markRuleInfo.markRule == 2 && scope.row.markStatus == 2)
                  "
                  size="mini"
                  type="primary"
                  @click="review(scope.row)"
                  >重新评分</el-button
                >
                <el-button
                  v-throttle
                  v-else
                  size="mini"
                  type="primary"
                  @click="review(scope.row)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-row>

      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="markRecordSizeChange"
          @current-change="markRecordCurrentChange"
          :current-page="markRecordPage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="markRecordPage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="markRecordPage.totalSize"
        ></el-pagination>
      </el-row>
    </div>
    <el-row
      class="arbitration-pool-content"
      v-if="markTeacher.teacherType == 3 && this.markRuleInfo.markRule == 2"
    >
      <p>仲裁池</p>
      <div>
        <el-card shadow="always">
          <!-- ,'backgroundColor':'#dfe6ec' -->
          <el-table
            v-loading="loading"
            stripe
            border
            ref="paperMarkingTable"
            :data="arbitrationList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              background: '#DFE6EC',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="operationTestName"
              label="题目名称"
              show-overflow-tooltip
              :width="1000"
            ></el-table-column>
            <el-table-column
              prop="num"
              label="数量"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="操作" :min-width="15" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  v-throttle
                  size="mini"
                  type="primary"
                  @click="arbitrate(scope.row, 'arbitrate')"
                  >仲裁</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-row>

    <el-row class="rated-paper-content" v-if="markTeacher.teacherType == 3">
      <p>
        已评试卷
        <span style="margin-left: 20px; color: #409eff; font-weight: bold"
          >已抽查{{ spotCheckResult.checkNum }}份，抽查比为{{
            spotCheckResult.checkRate
          }}%</span
        >
      </p>
      <div>
        <el-form :inline="true">
          <el-form-item label="评分时间" class="formItemBoxStyle">
            <el-date-picker
              v-model="achievementSummaryQueryParam.markTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="
                markTimeChange(
                  achievementSummaryQueryParam.markTime,
                  'achievementSummary'
                )
              "
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="评分范围" class="formItemBoxStyle">
            <el-input
              class="inputScore"
              v-model="achievementSummaryQueryParam.scoreStart"
              style="width: 70px"
              @blur="reloadAchievementSummaryList"
              @input="
                (e) =>
                  (achievementSummaryQueryParam.scoreStart = $verifyInput(e, 1))
              "
            ></el-input
            >&nbsp;至&nbsp;
            <el-input
              class="inputScore"
              v-model="achievementSummaryQueryParam.scoreEnd"
              style="width: 70px; text-align: center"
              @blur="reloadAchievementSummaryList"
              @input="
                (e) =>
                  (achievementSummaryQueryParam.scoreEnd = $verifyInput(e, 1))
              "
            ></el-input>
          </el-form-item>

          <el-form-item label="选择题目" class="formItemBoxStyle">
            <el-select
              v-model="achievementSummaryQueryParam.operationTestId"
              placeholder="请选择题目"
              style="width: 240px"
              @change="reloadAchievementSummaryList"
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in markRecordResultParam.operationTests"
                :key="item.operationTestId"
                :label="item.operationTestName"
                :value="item.operationTestId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="评阅人" class="formItemBoxStyle">
            <el-select
              v-model="achievementSummaryQueryParam.teacherId"
              placeholder="全部"
              style="width: 110px"
              @change="reloadAchievementSummaryList"
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in markRecordResultParam.markTeachers"
                :key="item.teacherId"
                :label="item.teacherName"
                :value="item.teacherId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择状态" class="formItemBoxStyle">
            <el-select
              v-model="achievementSummaryQueryParam.checkStatus"
              placeholder="全部"
              style="width: 110px"
              @change="reloadAchievementSummaryList"
            >
              <el-option label="全部" value></el-option>
              <el-option label="已抽查" :value="true"></el-option>
              <el-option label="未抽查" :value="false"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              v-throttle
              type="primary"
              icon="el-icon-refresh"
              @click="resetAchievementSummaryQueryParam"
              >刷新</el-button
            >
            <el-button
              v-throttle
              type="primary"
              @click="arbitrate('', 'random')"
              >随机抽查</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <div>
        <el-card shadow="always">
          <!-- ,'backgroundColor':'#dfe6ec' -->
          <el-table
            v-loading="loading"
            stripe
            border
            ref="paperMarkingTable"
            :data="achievementSummaryList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              background: '#DFE6EC',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="gmtRating"
              label="评分时间"
              show-overflow-tooltip
              :formatter="customFormatter"
              :width="180"
            ></el-table-column>
            <el-table-column
              prop="score"
              label="评分"
              show-overflow-tooltip
              :width="90"
            ></el-table-column>
            <el-table-column
              prop="markTeachers"
              label="评阅人"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-for="(item, index) in scope.row.markTeachers"
                  :key="index"
                >
                  <span>{{ item.teacherName }}</span>
                  <span v-if="index < scope.row.markTeachers.length - 1"
                    >,</span
                  >
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="operationTestName"
              label="题目名称"
              show-overflow-tooltip
              :width="500"
            ></el-table-column>
            <el-table-column
              prop="checkStatus"
              label="状态"
              show-overflow-tooltip
              :width="90"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.checkStatus">已抽查</span>
                <span v-if="!scope.row.checkStatus">未抽查</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="summaryHistories"
              label="记录"
              :min-width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div style="max-height: 45px; overflow-y: auto">
                  <div
                    v-for="(item, index) in scope.row.summaryHistories"
                    :key="index"
                  >
                    <span
                      v-if="
                        item.operationType != '' &&
                        item.operationType != null &&
                        item.operationType != undefined
                      "
                    >
                      <!--  <span v-if="item.operationType==1">&nbsp;已评分</span>
                    <span v-if="item.operationType==2">&nbsp;已仲裁</span>
                    <span v-if="item.operationType==3">&nbsp;抽查评分</span>
                    <span v-if="item.operationType==4">&nbsp;已提出异议</span>
                      <span v-if="item.operationType==5">&nbsp;重新评分</span>-->
                      <span v-if="item.operationType == 6">
                        {{
                          formatDateTime(
                            new Date(item.gmtRating),
                            'yyyy-MM-dd hh:mm:ss'
                          )
                        }}
                        &nbsp;{{ item.userName }}&nbsp;已抽查
                      </span>
                      <!-- <br /> -->
                    </span>
                    <span v-if="item.operationType < 6">
                      <span>{{
                        formatDateTime(
                          new Date(item.gmtRating),
                          'yyyy-MM-dd hh:mm:ss'
                        )
                      }}</span>
                      <span>&nbsp;{{ item.userName }}修改分数为</span>
                      <span>&nbsp;{{ item.score }}</span>
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" :min-width="25" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  v-throttle
                  size="mini"
                  type="primary"
                  @click="arbitrate(scope.row, 'evaluated')"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>

      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="achievementSummarySizeChange"
          @current-change="achievementSummaryCurrentChange"
          :current-page="achievementSummaryPage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="achievementSummaryPage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="achievementSummaryPage.totalSize"
        ></el-pagination>
      </el-row>
    </el-row>

    <!-- <el-row class="marking-details-content" v-if="markTeacher.teacherType == 3">
      <p id="markingTeacher">评卷详情</p>
      <div>
        <el-card
          shadow="always"
          v-for="item in allPersonalCountList"
          :key="item.teacherId"
          style="margin-bottom: 10px"
        >
          <el-table
            v-loading="loading"
            stripe
            border
            ref="paperMarkingTable"
            :data="item.operationTestCounts"
            tooltip-effect="dark"
            style="width: 100%"
            show-summary
            :summary-method="getSummaries"
            header-align="center"
            :header-cell-style="{
              'text-align': 'center',
              background: '#DFE6EC',
            }"
          >
            <el-table-column
              align="center"
              label="评卷人"
              show-overflow-tooltip
            >
              <div>{{ item.teacherName }}</div>
            </el-table-column>
            <el-table-column
              align="center"
              prop="operationTestName"
              label="题目名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              prop="markNum"
              label="评卷数量"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              prop="operationTestAverage"
              label="平均分"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-row> -->

    <el-row class="marking-details-content" v-if="markTeacher.teacherType == 3">
      <p id="markingTeacher">组员评卷详情</p>
      <div>
        <el-form :inline="true">
          <el-form-item label="阅卷老师" class="formItemBoxStyle">
            <el-select
              v-model="markingCrewParam.teacherId"
              @change="
                () => {
                  this.markingCrewPage.pageIndex = 1
                  this.changVal()
                }
              "
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in allPersonalCountList"
                :key="item.teacherId"
                :label="item.teacherName"
                :value="item.teacherId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择题目" class="formItemBoxStyle">
            <el-select
              v-model="markingCrewParam.operationTestId"
              @change="
                () => {
                  this.markingCrewPage.pageIndex = 1
                  this.changVal()
                }
              "
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in markRecordResultParam.operationTests"
                :key="item.operationTestId"
                :label="item.operationTestName"
                :value="item.operationTestId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-card shadow="always">
          <el-table
            v-loading="markingCrewLoading"
            stripe
            border
            ref="markingCrewData"
            :data="markingCrewData"
            tooltip-effect="dark"
            style="width: 100%"
            @sort-change="changeSort"
            header-align="center"
            :header-cell-style="{
              'text-align': 'center',
              background: '#DFE6EC',
            }"
          >
            <el-table-column
              align="center"
              label="序号"
              width="90"
              type="index"
            ></el-table-column>
            <el-table-column
              prop="teacherName"
              align="center"
              label="评卷人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="operationTestName"
              label="题目名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              sortable="custom"
              width="120"
              align="center"
              prop="markCount"
              label="评卷数量"
            ></el-table-column>
            <el-table-column
              sortable="custom"
              width="120"
              align="center"
              prop="avgScore"
              label="平均分"
            ></el-table-column>
          </el-table>
        </el-card>
        <el-pagination
          style="margin-top: 10px"
          @size-change="markingCrewPageSizeChange"
          @current-change="markingCrewPageCurrentChange"
          :current-page="markingCrewPage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="markingCrewPage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="markingCrewPage.total"
        ></el-pagination>
      </div>
    </el-row>

    <el-row>
      <el-dialog
        :visible.sync="dialogVisible"
        width="70%"
        :before-close="handleClose"
        @close="closed"
      >
        <div slot="title" id="dialog-title">
          <span>{{ examInfo.examName }}--评卷安排</span>
          <span>本场考试已于{{ examInfo.examStartTimeFormat }}开始，</span>
          <span v-if="examInfo.examStatus == 2">等待考试</span>
          <span v-if="examInfo.examStatus == 3">正在考试</span>
          <span v-if="examInfo.examStatus == 4">考试完成</span>
        </div>
        <div id="mark-rule-content">
          <p>评卷规则</p>
          <el-row :gutter="5">
            <el-col :span="8">
              <el-card shadow="always">
                <div>
                  <p v-if="markRuleInfo.markPass">
                    <span>及格规则</span>
                    <span>设置及格分数为 {{ markRuleInfo.markPass }} 分</span>
                  </p>
                  <p v-if="markRuleInfo.markType != 1">
                    <span>评卷规则</span>
                    <span
                      >每位老师评阅的考生数
                      {{ markRuleInfo.sceneMarkNum }} 个</span
                    >
                  </p>
                  <p v-if="markRuleInfo.reviewStatus == 1">
                    <span>回评审查</span>
                    <span>{{
                      markRuleInfo.reviewStatus == 0 ? '禁用' : '启用'
                    }}</span>
                  </p>
                  <p>
                    <span>自定义分数段</span>
                    <span>
                      低分: {{ markRuleInfo.lowScore + '%' }} 及格:
                      {{ markRuleInfo.passingScore + '%' }} 优分:
                      {{ markRuleInfo.excellentScore + '%' }} 高分:
                      {{ markRuleInfo.highScore + '%' }}
                    </span>
                  </p>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8" v-if="markRuleInfo.markType == 1">
              <el-card shadow="always">
                <div>
                  <p>
                    <el-row>
                      <el-col :span="12">
                        <span>评卷规则</span>
                        <span>{{
                          markRuleInfo.markRule == 1 ? '单评制' : '多评制'
                        }}</span>
                      </el-col>
                      <el-col :span="12">
                        <el-select
                          v-model="markRuleInfo.subjectType"
                          placeholder="请选择"
                          :disabled="true"
                        >
                          <el-option label="物理" :value="1"></el-option>
                          <el-option label="生物" :value="2"></el-option>
                          <el-option label="化学" :value="3"></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </p>

                  <p v-if="markRuleInfo.reviewStatus == 1">
                    <span>回评规则</span>
                    <span
                      >轮次容量为
                      {{ markRuleInfo.reviewCapacity }} 份，分差警示值为
                      {{ markRuleInfo.reviewThreshold }} 分</span
                    >
                  </p>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8" v-if="markRuleInfo.markRule == 2">
              <el-card shadow="always">
                <div>
                  <p>
                    <span>多评规则</span>
                    <span>分差阈值为 {{ markRuleInfo.markThreshold }} 分</span>
                  </p>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <div style="text-align: center; margin-top: 20px">
            <el-button
              v-throttle
              type="danger"
              size="medium"
              @click="closeDialog"
              >关闭</el-button
            >
          </div>
        </div>
      </el-dialog>
    </el-row>

    <el-row
      style="margin-top: 20px; text-align: center"
      v-if="markTeacher.teacherType == 3"
    >
      <el-button v-throttle size="medium" type="primary" @click="doneMark"
        >完成评卷</el-button
      >
    </el-row>
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import {
  getPersonalRecordApi,
  getMarkTeacherApi,
  getQuestionListApi,
  getPersonalCountListApi,
  getMarkRecordResultParamApi,
  getMarkRuleInfoApi,
  getMarkRecordListApi,
  getArbitrationListApi,
  getAchievementSummaryListApi,
  getSpotCheckRateApi,
  finishMarkApi,
  removeOccupyApi,
  getTeacherCountScore,
} from '@/api/marking/markingDetails.js'
import { getOneTestApi } from '@/api/marking/markingVideoFormal.js'
import { isEmpty, formatDate } from '@/utils/util'
import { getStore, removeStore, setStore } from '@/utils/token'
export default {
  components: { ElImageViewer },
  name: 'markingDetails',
  data() {
    return {
      progressStrokeWidth: 15,
      progressWidth: 150,
      loading: false,
      paperMarkingList: [],
      markRecordPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      achievementSummaryPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      //考试信息
      examInfo: {
        examId: 0,
        examMarkStatus: 0,
        examName: '',
        examStartTime: 0,
        examStartTimeFormat: '',
        examStatus: 0,
        markRuleStatus: 0,
        markTypes: [],
        reading: false,
        subjectTypes: [],
      },
      operationTestDisabled: true,
      //个人阅卷信息
      personalRecord: {},
      //阅卷教师信息
      markTeacher: {},
      question: {},
      //阅卷考试题目
      questionList: [],
      //个人阅卷统计
      personalCountList: [],
      //全部阅卷老师统计
      allPersonalCountList: [],
      //阅卷规则信息
      markRuleInfo: {},
      //个人阅卷记录
      markRecordList: [],
      markRecordQueryParam: {
        markTime: [],
        checkStatus: '',
        examId: '',
        gmtRatingEnd: '',
        gmtRatingStart: '',
        markStatus: '',
        operationTestId: '',
        scoreEnd: '',
        scoreStart: '',
        teacherId: '',
      },
      //评卷记录查询参数
      markRecordResultParam: {},
      // 仲裁卷列表
      arbitrationList: [],
      //已评试卷列表
      achievementSummaryList: [],
      achievementSummaryQueryParam: {
        markTime: [],
        checkStatus: '',
        examId: '',
        gmtRatingEnd: '',
        gmtRatingStart: '',
        markStatus: '',
        operationTestId: '',
        scoreEnd: '',
        scoreStart: '',
        teacherId: '',
      },
      //抽查比例
      spotCheckResult: {},
      //试题Id
      chonseOperationTestId: '',
      title: '',
      dialogVisible: false,

      //
      markingCrewLoading: false,
      markingCrewData: [],
      markingCrewParam: {
        operationTestId: '',
        //  1-评卷数量 2-平均分
        paramName: '',
        // 1-升序 2-降序
        sortType: '',
        teacherId: '',
      },
      markingCrewPage: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
    }
  },
  watch: {},
  mounted() {
    let markExamInfo = JSON.parse(getStore('markExamInfo'))
    if (!isEmpty(markExamInfo)) {
      this.examInfo = markExamInfo
      this.examInfo.examStartTimeFormat = formatDate(
        new Date(this.examInfo.examStartTime),
        'yyyy-MM-dd hh:mm:ss'
      )
    }
    this.init()
  },

  beforeDestroy() {
    if (this.$route.path !== '/markingVideo/markingVideoReview') {
      removeStore('markRecordQueryParam')
      removeStore('markRecordPage')
    } else if (this.$route.path !== '/markingVideo/markingVideoArbitrate') {
      removeStore('achievementSummaryQueryParam')
      removeStore('achievementSummaryPage')
    }
  },
  methods: {
    async init() {
      if (isEmpty(this.examInfo.examId)) {
        return
      }
      await this.removeOccupy()
      //获取个人阅卷记录
      this.getPersonalRecord()
      //获取阅卷教师信息
      this.getMarkTeacher()
      //获取教师阅卷考试题目
      this.getQuestionList()
      //获取评卷记录查询参数
      this.getMarkRecordResultParam()
      if (getStore('achievementSummaryQueryParam')) {
        this.achievementSummaryQueryParam = JSON.parse(
          getStore('achievementSummaryQueryParam')
        )
        this.achievementSummaryPage = JSON.parse(
          getStore('achievementSummaryPage')
        )
      }
      //阅卷组长获取已评试卷列表
      await this.getAchievementSummaryList()
      this.getMarkingCrewData({})
    },
    //【阅卷】解除占用
    async removeOccupy() {
      await removeOccupyApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          // this.$router.push({ path: `/marking/markingDetails` })
        } else {
          this.msgError(res.msg)
        }
      })
    },
    //获取个人阅卷记录
    getPersonalRecord() {
      getPersonalRecordApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.personalRecord = {}
          this.personalRecord = res.data
        }
      })
    },
    //获取阅卷教师信息
    getMarkTeacher() {
      getMarkTeacherApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.markTeacher = {}
          this.markTeacher = res.data
          console.log(
            `阅卷老师类型:${
              this.markTeacher.teacherType == 3 ? '组长' : '组员'
            }`
          )
          if (getStore('markRecordQueryParam')) {
            this.markRecordQueryParam = JSON.parse(
              getStore('markRecordQueryParam')
            )
            this.markRecordPage = JSON.parse(getStore('markRecordPage'))
          }
          this.getPersonalCountList()
          //根据考试id获取阅卷规则信息
          this.getMarkRuleInfo()
          //组长获取抽查比例
          if (this.markTeacher.teacherType == 3) {
            this.getSpotCheckRate()
          }
        }
      })
    },
    //获取教师阅卷考试题目
    getQuestionList() {
      getQuestionListApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.questionList = []
          this.questionList = res.data
        }
      })
    },
    getPersonalCountList() {
      getPersonalCountListApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.allPersonalCountList = []
          this.allPersonalCountList = res.data
          this.personalCountList = []
          this.allPersonalCountList.forEach((item) => {
            if (item.teacherId == this.markTeacher.teacherId) {
              this.personalCountList = item.operationTestCounts
            }
          })
        }
      })
    },
    getMarkRuleInfo() {
      let fd = new FormData()
      fd.append('examId', this.examInfo.examId)
      getMarkRuleInfoApi(fd).then((res) => {
        if (res.success) {
          res.data.markSubjects.forEach((item) => {
            if (item.subjectType == this.markTeacher.subjectType) {
              this.markRuleInfo = {}
              this.markRuleInfo = item
              // 获取个人阅卷记录
              this.getMarkRecordList()
              if (
                this.markTeacher.teacherType == 3 &&
                this.markRuleInfo.markRule == 2
              ) {
                //阅卷组长获取仲裁卷列表
                this.getArbitrationList()
              }
            }
          })
        }
      })
    },
    // 获取个人阅卷记录
    getMarkRecordList() {
      this.markRecordQueryParam.examId = this.examInfo.examId
      getMarkRecordListApi(
        this.markRecordPage.pageIndex,
        this.markRecordPage.pageSize,
        this.markRecordQueryParam
      ).then((res) => {
        if (res.success) {
          this.markRecordList = []
          this.markRecordList = res.data
          this.markRecordPage.totalSize = 0
          this.markRecordPage.totalSize = res.total
        }
      })
    },
    //组长获取抽查比例
    getSpotCheckRate() {
      getSpotCheckRateApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.spotCheckResult = {}
          this.spotCheckResult = res.data
        } else {
          this.msgError(res.msg)
        }
      })
    },
    //刷新
    refresh() {
      this.init()
      this.resetMarkRecordQueryParam()
      this.resetAchievementSummaryQueryParam()
    },
    reloadMarkRecordList() {
      this.markRecordPage.pageIndex = 1
      this.getMarkRecordList()
    },
    resetMarkRecordQueryParam() {
      this.markRecordQueryParam = {
        markTime: [],
        checkStatus: '',
        examId: '',
        gmtRatingEnd: '',
        gmtRatingStart: '',
        markStatus: '',
        operationTestId: '',
        scoreEnd: '',
        scoreStart: '',
        teacherId: '',
      }
      this.getMarkRecordList()
    },
    //阅卷组长获取仲裁卷列表
    getArbitrationList() {
      getArbitrationListApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.arbitrationList = []
          this.arbitrationList = res.data
        } else {
          this.msgError(res.msg)
        }
      })
    },
    //阅卷组长获取已评试卷列表
    async getAchievementSummaryList() {
      this.achievementSummaryQueryParam.examId = this.examInfo.examId
      await getAchievementSummaryListApi(
        this.achievementSummaryPage.pageIndex,
        this.achievementSummaryPage.pageSize,
        this.achievementSummaryQueryParam
      ).then((res) => {
        if (res.success) {
          this.achievementSummaryList = []
          this.achievementSummaryList = res.data
          this.achievementSummaryPage.totalSize = 0
          this.achievementSummaryPage.totalSize = res.total
        }
      })
    },
    //获取评卷记录查询参数
    getMarkRecordResultParam() {
      getMarkRecordResultParamApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.markRecordResultParam = {}
          this.markRecordResultParam = res.data
        }
      })
    },
    lookRule() {
      this.dialogVisible = true
    },
    operationTestChange() {
      this.questionList.forEach((item) => {
        if (item.operationTestId == this.chonseOperationTestId) {
          this.question = {}
          this.question = item
        }
      })
      if (!isEmpty(this.chonseOperationTestId)) {
        this.operationTestDisabled = false
      }
    },
    startMarkingTest() {
      //没有开启回评审查
      if (this.markRuleInfo.reviewStatus == 0) {
        if (this.question.num == 0) {
          this.msgWarning('剩余试卷为0')
          return
        }
      }
      this.getOneTest()
        .then((res) => {
          if (res.success) {
            if (!this.operationTestDisabled) {
              this.$router.push({
                path: '/markingVideo/markingVideoFormal',
                query: {
                  examId: this.examInfo.examId,
                  operationTestId: this.chonseOperationTestId,
                },
              })
            }
          } else {
            if (res.code != 500) {
              if (
                res.code == 831 ||
                res.code == 817 ||
                res.code == 814 ||
                res.code == 821
              ) {
                this.msgSuccess(res.msg)
              } else if (res.code == 805) {
                //需要回评审查
                this.$router.push({
                  path: '/markingVideo/markingVideoFormal',
                  query: {
                    examId: this.examInfo.examId,
                    operationTestId: this.chonseOperationTestId,
                  },
                })
              } else {
                this.msgWarning('剩余试卷被占用')
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //获取一份试卷
    async getOneTest() {
      let data = {
        channel: 0,
      }
      let flag = false
      await getOneTestApi(
        this.examInfo.examId,
        this.chonseOperationTestId,
        data
      )
        .then((res) => {
          flag = res
        })
        .catch((err) => {
          flag = err
        })
      return Promise.resolve(flag)
    },
    //回评|查看
    review(row) {
      // 存值
      setStore(
        'markRecordQueryParam',
        JSON.stringify(this.markRecordQueryParam)
      )
      setStore('markRecordPage', JSON.stringify(this.markRecordPage))
      //
      this.$router.push({
        path: `/markingVideo/markingVideoReview`,
        query: {
          examId: row.examId,
          markRecordId: row.examMarkRecordId,
          markStatus: row.markStatus,
          markRule: this.markRuleInfo.markRule,
        },
      })
    },
    //仲裁|已评卷查看
    arbitrate(row, text) {
      let examId = this.examInfo.examId
      let teacherId = this.markTeacher.teacherId
      setStore(
        'achievementSummaryQueryParam',
        JSON.stringify(this.achievementSummaryQueryParam)
      )
      setStore(
        'achievementSummaryPage',
        JSON.stringify(this.achievementSummaryPage)
      )
      switch (text) {
        case 'evaluated':
          this.$router.push({
            path: `/markingVideo/markingVideoArbitrate`,
            query: {
              examId: examId,
              achievementSummaryId: row.achievementSummaryId,
              text: text,
              teacherId: teacherId,
            },
          })
          break
        case 'arbitrate':
          if (row.num == 0) {
            this.msgWarning('没有仲裁卷了')
            return
          }
          this.$router.push({
            path: `/markingVideo/markingVideoArbitrate`,
            query: {
              examId: examId,
              operationTestId: row.operationTestId,
              text: text,
              teacherId: teacherId,
            },
          })
          break
        case 'random':
          if (this.spotCheckResult.checkRate == 100) {
            this.msgWarning('没有未抽查的试卷')
            return
          }
          this.$router.push({
            path: `/markingVideo/markingVideoArbitrate`,
            query: {
              examId: examId,
              text: text,
              teacherId: teacherId,
            },
          })
          break
      }
    },
    doneMark() {
      let examId = this.examInfo.examId
      finishMarkApi(examId).then((res) => {
        if (res.success) {
          removeStore('markExamInfo')
          this.$router.push({ name: 'markingPlainManage' })
        } else {
          this.msgError(res.msg)
        }
      })
    },
    /* 分页操作 */
    markRecordCurrentChange(val) {
      this.markRecordPage.pageIndex = val
      this.getMarkRecordList()
    },
    markRecordSizeChange(val) {
      this.markRecordPage.pageSize = val
      this.getMarkRecordList()
    },
    achievementSummaryCurrentChange(val) {
      this.achievementSummaryPage.pageIndex = val
      this.getAchievementSummaryList()
    },
    achievementSummarySizeChange(val) {
      this.achievementSummaryPage.pageSize = val
      this.getAchievementSummaryList()
    },
    markTimeChange(value, text) {
      let gmtRatingStart = ''
      let gmtRatingEnd = ''
      if (!isEmpty(value)) {
        if (value.length > 0) {
          gmtRatingStart = Date.parse(value[0])
          gmtRatingEnd = Date.parse(value[1])
        }
      }
      switch (text) {
        case 'achievementSummary':
          this.achievementSummaryQueryParam.gmtRatingStart = gmtRatingStart
          this.achievementSummaryQueryParam.gmtRatingEnd = gmtRatingEnd
          this.reloadAchievementSummaryList()
          break
        case 'markRecord':
          this.markRecordQueryParam.gmtRatingStart = gmtRatingStart
          this.markRecordQueryParam.gmtRatingEnd = gmtRatingEnd
          this.getMarkRecordList()
          break
      }
    },
    reloadAchievementSummaryList() {
      this.achievementSummaryPage.pageIndex = 1
      this.getAchievementSummaryList()
    },
    resetAchievementSummaryQueryParam() {
      this.achievementSummaryQueryParam = {
        markTime: [],
        checkStatus: '',
        examId: '',
        gmtRatingEnd: '',
        gmtRatingStart: '',
        markStatus: '',
        operationTestId: '',
        scoreEnd: '',
        scoreStart: '',
        teacherId: '',
      }
      this.getAchievementSummaryList()
    },
    closeDialog() {
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisible = false
    },
    closed() {
      this.dialogVisible = false
    },
    /* 时间格式化 */
    formatDateTime(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'gmtRating':
          if (!isEmpty(row.gmtRating)) {
            return formatDate(new Date(row.gmtRating), 'yyyy-MM-dd hh:mm:ss')
          }
          break
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let values = data.map((item) => Number(item[column.property]))
        if (index == 3) {
          console.log('平均分：', data)
          values = data.map((item) => Number(item['totalScore']))
          if (sums[2] == 0) {
            sums[index] = 0
            return
          }
        }
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = ''
        }
        if (index == 3) {
          sums[index] = (sums[index] / sums[2]).toFixed(1)
        }
      })
      return sums
    },
    //
    markingCrewPageCurrentChange(val) {
      this.markingCrewPage.pageIndex = val
      this.changVal()
    },
    markingCrewPageSizeChange(val) {
      this.markingCrewPage.pagSize = val
      this.markingCrewPage.pageIndex = 1
      this.changVal()
    },
    // 排序触发
    changeSort({ column, prop, order }) {
      const sortType = {
        markCount: 1,
        avgScore: 2,
        ascending: 1,
        descending: 2,
      }
      this.markingCrewParam.paramName = sortType[prop]
      this.markingCrewParam.sortType = sortType[order]
      this.changVal()
    },
    changVal() {
      const condition = JSON.parse(JSON.stringify(this.markingCrewParam))
      for (let key in condition) {
        if (condition[key] == '' || condition[key] == undefined) {
          delete condition[key]
        }
      }
      // const condition = Object.entries(this.markingCrewParam).reduce(
      //   (newCondition, [key, value]) => {
      //     if (value !== '' && value !== undefined) {
      //       newCondition[key] = value
      //     }
      //     return newCondition
      //   },
      //   {}
      // )
      this.getMarkingCrewData(condition)
    },
    getMarkingCrewData(condition) {
      this.markingCrewLoading = true
      getTeacherCountScore(
        this.markingCrewPage.pageIndex,
        this.markingCrewPage.pageSize,
        this.examInfo.examId,
        condition
      )
        .then((res) => {
          if (res.success) {
            this.markingCrewData = res.data
            this.markingCrewPage.total = res.total
          }
          this.markingCrewLoading = false
        })
        .catch((err) => {
          this.markingCrewLoading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.router-view {
  padding: 0 20px 10px 20px;
}

#marking-content-header {
  padding: 15px 5px;
  border-left: 10px solid #409eff;
  background-color: rgba(242, 242, 242, 1);
}

#content-body-progress {
  margin-top: 20px;
}

.el-progress {
  display: flex;
  justify-content: center;
  align-items: center;
}

#content-body-progress > .el-col-8 > .el-card > .el-card__body > p:first-child,
#content-body-progress
  > .el-col-16
  > .el-card
  > .el-card__body
  > p:first-child {
  font: normal bold 20px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #778ca2;
  margin-left: 10px;
}

#content-body-progress
  > .el-col-8
  > .el-card
  > .el-card__body
  > .paper-info-contetnt
  > div {
  margin-top: 25px;
  text-align: center;
}

#content-body-progress
  > .el-col-8
  > .el-card
  > .el-card__body
  > .paper-info-contetnt
  > div
  > span {
  display: inline-block;
  width: 100px;
  color: #909399;
  text-align: center;
  font-size: 16px;
}

.sub-flex-span {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #febb67;
  border: 1px solid #febb67;
}

.badge-flex-span {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #2295ff;
  border: 1px solid #2295ff;
}

.my-progress {
  height: 334px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-progress > .el-col-8 {
  text-align: center;
}

.my-progress > .el-col-8 > img {
  border-radius: 50%;
}

.my-progress > .el-col-8 > img:hover {
  transition: all 0.5s ease-out;
  transform: scale(1.05);
  cursor: pointer;
}

.my-progress > .el-col-8 > div {
  margin-top: 20px;
}

.my-progress > .el-col-16 {
  background-color: rgba(243, 248, 255);
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
}

.my-progress > .el-col-16 > div > ul > li > span {
  margin: 0px 20px;
  color: #778ca2;
  display: inline-block;
  line-height: 35px;
}

.my-progress > .el-col-16 > div > ul > li > span:first-child {
  width: 320px;
  text-align: right;
}

.start-marking-content {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-marking-content > .el-form {
  text-align: center;
}

::v-deep
  .start-marking-content
  > .el-form
  > .el-form-item:first-child
  > .el-form-item__content
  > .el-select
  > .el-input
  > .el-input__inner {
  width: 500px;
}

.personal-marking-record > p:first-child,
.arbitration-pool-content > p:first-child,
.rated-paper-content > p:first-child,
.marking-details-content > p:first-child {
  font: normal bold 18px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #778ca2;
}

.arbitration-pool-content,
.rated-paper-content,
.marking-details-content {
  margin-top: 25px;
}

::v-deep #marking-content .el-form-item__label {
  padding: 0;
  text-align: center;
  background-color: #dfe6ec;
}

ul {
  list-style-type: none;
}

.inputScore > ::v-deep .el-input__inner {
  text-align: center;
}

#mark-rule-content {
  font-family: 'Source Han Sans CN ', 'Source Han Sans CN', sans-serif;
}

#mark-rule-content > p {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

::v-deep #mark-rule-content > .el-row > .el-col > .el-card > .el-card__body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 122px;
}

#dialog-title > span {
  color: #606266;
}

#dialog-title > span:nth-child(2) {
  margin-left: 50px;
}

#mark-rule-content
  > .el-row
  > .el-col
  > .el-card
  > .el-card__body
  > div
  > p
  > span {
  color: #606266;
  // font-size: 15px;
}

#mark-rule-content
  > .el-row
  > .el-col
  > .el-card
  > .el-card__body
  > div
  > p
  > span:last-child,
#mark-rule-content
  > .el-row
  > .el-col
  > .el-card
  > .el-card__body
  > div
  > p
  > .el-row
  > .el-col
  > span:last-child {
  display: inline-block;
  margin-left: 20px;
}

::v-deep
  #mark-rule-content
  > .el-row
  > .el-col
  > .el-card
  > .el-card__body
  > div
  > p
  > .el-row
  > .el-col:first-child {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}

/* #mark-rule-content > ::v-deep .el-card .el-card__body span:last-child {
  display: inline-block;
  margin-left: 20px;
} */
</style>
